import React from "react";
import "./TypewriterText.css";

class TypewriterText extends React.Component {
    constructor(props) {
        super(props);
        this.id = `typewriter-text-${Math.floor(Math.random() * 9999)}`;
        this.typeSpeed = this.props.typeSpeed || 50;
    }

    componentDidMount() {
        const currentTyping = document.querySelector(`#${this.id} > .typing`);
        const content = currentTyping.innerHTML;
        currentTyping.innerHTML = "";

        let index = 0;

        const typeInterval = setInterval(() => {
            currentTyping.innerHTML += content[index];
            index++;
            if(index >= content.length) clearInterval(typeInterval);
        }, this.typeSpeed);
    }

    render() {
        const children = this.props.children;
        if(!children)
            return (
                <div className="typewriter-text" id={this.id}>
                    {
                        this.props.children.map((item, index) => {
                            return index === 0 ? 
                                React.cloneElement(item, {
                                    className: `${item.props.className || ""} typing`
                                }) : 
                            item;
                        })
                    }
                </div>
            );
        else
            return (
                <div className="typewriter-text" id={this.id}>
                    {
                        React.cloneElement(children, {
                            className: `${children.props.className || ""} typing`
                        })
                    }
                </div>
                
            );
    }
}

export default TypewriterText;
