import React from "react";

import TypewriterText from "../elements/TypewriterText";
//import AnimatedBackground from "../elements/AnimatedBackground";
import "./WIP.css";

class Home extends React.Component {
    render() {
		return (
			<div>
                <TypewriterText>
                    <p>Page Work in Progress.</p>
                   {/*  <div>
                        <h1>test</h1>
                    </div> */}
                </TypewriterText>
            </div>
		);
    }
}

export default Home;
