//import logo from './logo.svg';
import './App.css';
import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";
import WIP from "../pages/WIP";
import URLShorter from "../../projects/url-shortener/pages/URLShorter"
import Error from "../pages/Error";

class App extends React.Component {
    render() {
		return (
			<>
				<Router>
					<Routes>
						<Route path="/" element={<WIP />} />
					</Routes>
				</Router>
				<Router basename='/url-shortener'>
					<Routes>
						<Route path="*" element={<Error/>} />
						<Route path="/" element={<URLShorter/>} />
					</Routes>
				</Router>
			</>
		);
    }
}

export default App;
