import React from "react";

class Error extends React.Component {
    render() {
		return (
			<p>Site does not exist :(</p>
		);
    }
}

export default Error;
