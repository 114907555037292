import React from "react";
import urlShortener from '../../../images/url-shortener.svg';
import config from "../../../config.json";
import "./URLShorter.css";

class URLShorter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            urls: [],
            error: "",
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    
    async handleSubmit (e) {
        e.preventDefault();
        const response = await (await fetch(`https://quick-short.ml/short`, {
            method: 'post',
            body: JSON.stringify({
                url: e.target.querySelector('input[name="link"]').value
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })).json();
        /* const response = {createdAt: Math.floor(new Date().getTime() / 1000), url: "https://short-url.ml/12345"} */
        this.setState({urls:[response, ...this.state.urls]}, () => {
            localStorage.setItem("url-history", JSON.stringify(this.state.urls))
        })
    }

    componentDidMount() {
        this.setState({urls: JSON.parse(localStorage.getItem("url-history")) || []});
    }

    render() {
		return (
            <>
                <form id="url-shortener" onSubmit={this.handleSubmit}>
                    <img src={urlShortener} width="200"/>
                    <div>
                        <input type="url" name="link" placeholder="Enter URL..." />
                        <input type="submit" name="submit" value="Short" />
                    </div>
                </form>
                <ul id="url-list">
                    {this.state.urls.map(item => {
                        return (
                            <li key={item.createdAt}>
                                <a href={item.url}>
                                    {item.url}
                                </a>
                            </li>
                        )
                    })}
                </ul>
            </>
		);
    }
}

export default URLShorter;
